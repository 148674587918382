export const getCurrentTimeIndex = ({ times, currentTime, start = 0, end = times.length - 1 }: {times: number[]; currentTime: number; start?: number; end?: number;}): number | null => {
  const midIndex = Math.floor((start + end) / 2);

  if (times[midIndex] <= currentTime && times[midIndex + 1] > currentTime) {
    return midIndex;
  }

  if (start >= end) {
    return -1;
  }

  return currentTime < times[midIndex]
    ? getCurrentTimeIndex({ times, currentTime, start, end: midIndex - 1 })
    : getCurrentTimeIndex({ times, currentTime, start: midIndex + 1, end });
};

export const getElementFromTime = (time: number) => {
  return document.querySelector(`span[data-rw-start="${time}"]`) as HTMLSpanElement | null;
};

export const getStartTimeFromEl = (el: HTMLElement) => {
  const dataSet = el.dataset;
  if (dataSet.rwStart) {
    return parseFloat(dataSet.rwStart);
  }
};

export function getYoutubeIdFromUrl(youtubeUrl: string) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\/?\?v=|&v=)([^#&?]*).*/;
  const match = youtubeUrl.match(regExp);
  return match && match[2].length === 11
    ? match[2]
    : null;
}
